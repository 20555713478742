import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
        <Typography variant="caption">&copy; {t('all_rights_reserved', 'All rights reserved')}</Typography>
        <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
          {/*<Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
            {t('about_us', 'About us')}
          </Link>*/}
          <Link component={RouterLink} to="/docs/exCARE-ToS-PaS-End-User_EN.pdf" target="_blank" variant="caption" color="textPrimary">
            {t('terms_and_conditions_uppercase', 'Terms and conditions')}
          </Link>
        </Stack>
      </Stack>
    </>
  )
}

export default Footer;

import { UserProviderId, UserProviderName, UserRole } from "../models/IUser";

import ShadowMan from 'assets/images/shadow_man.png';
import ShadowWoman from 'assets/images/shadow_woman.png';

export const getInitials: (
  firstName: string | undefined,
  lastName: string | undefined
) => string = (firstName, lastName) => {
  if (firstName === undefined) {
    throw new Error("Given Name not found");
  }

  if (lastName === undefined) {
    throw new Error("Last Name not found");
  }

  return firstName[0].toUpperCase() + lastName[0].toUpperCase();
};

export const getUserRoleName: (role: UserRole | undefined) => string = (role) => {
  if (role === undefined) {
    return "Unkown";
  }
  switch (role) {
    case UserRole.Admin:
      return "Admin";
    case UserRole.Manager:
      return "Manager";
    case UserRole.Clinician:
      return "Clinician";
    case UserRole.Patient:
      return "Patient";
  }
};

export const getSonUserRoles: (role: UserRole) => UserRole[] = (role) => {
  switch(role) {
    case UserRole.Admin:
      return [UserRole.Manager, UserRole.Clinician, UserRole.Patient];
    case UserRole.Manager:
      return [UserRole.Clinician, UserRole.Patient];
    case UserRole.Clinician:
      return [UserRole.Patient];
    case UserRole.Patient:
      return [];
  }
}

export const getAvatar: (id: string) => string = (id) => {
  var lastChar = id.split('').pop()
  var isNumber = /\d/.test(lastChar!!)

  return isNumber ? ShadowMan : ShadowWoman
}

export const getUserProviderName: (userProviderId: string) => string = (userProviderId: string) => {
  switch (userProviderId) {
      case UserProviderId.CHAMP:
          return UserProviderName.CHAMP
      case UserProviderId.KORE:
          return UserProviderName.KORE
      case UserProviderId.AIQReady:
          return UserProviderName.AIQReady
      case UserProviderId.SMILE:
          return UserProviderName.SMILE
      case UserProviderId.VETLLAR:
          return UserProviderName.VETLLAR
      case UserProviderId.AURA:
          return UserProviderName.AURA
      case UserProviderId.ICARE:
        return UserProviderName.ICARE
      case UserProviderId.HEALTHCHAIN:
        return UserProviderName.HEALTHCHAIN
      default:
          return "Unknown";
  }
};
